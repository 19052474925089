<template>
  <div v-if="specEdit && print_settings">

    <div class="d-flex align-items-center justify-content-between mb-3 mt-2">
      <div class="fw-bold text-warning">{{ specEdit.description ? specEdit.description : 'Set the mixin properties' }}
      </div>

      <div>
        <button class="btn btn-sm btn-secondary me-2" @click="reset()">
          <i class="bi-arrow-counterclockwise"></i>
          Reset
        </button>

        <button class="btn btn-sm btn-secondary" @click="copyToClipboard()">
          <i class="bi-files"></i>
          {{ clipboardText === `\`${JSON.stringify(getSpec(specEdit))}\`` ? 'Copied!' : 'Copy' }}
        </button>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-12">
      <div class="col-md-2 mt-2 dataset-column">
        <select class="form-select form-select-sm" v-model="specEdit.dataset" @change="update('dataset')">
          <option v-for="(option, index) in options.dataset" :value="option.value" :key="'dataset' + index">
            {{ option.text }}
          </option>
        </select>
        <div class="form-text"> Dataset </div>
      </div>
      <div class="col-md-4 mt-2 category-column">
        <select class="form-select form-select-sm" v-model="specEdit.category" @change="update('category')">
          <option v-for="(option, index) in options.category" :value="option.value" :key="'category' + index">
            {{ option.text }}
          </option>
        </select>
        <div class="form-text">Category</div>
      </div>

      <div class="col-md-6 mt-2">
        <Multiselect v-model="specEdit.partitions" :options="options.partitions" track-by="value" label="text"
          mode="tags" :close-on-select="false" @select="update('partitions')" :class="darkMode ? 'dark-mode' : ''">
        </Multiselect>
        <div class="form-text">Partitions (leave empty unless you want to create a mixin for a specific partition)
        </div>
      </div>

    </div>

    <div class="row row-cols-1 row-cols-sm-1 row-cols-md-12">
      <div class="col-md-2 mt-2 stat-column" v-if="options.stat.length > 0">
        <select class="form-select form-select-sm" v-model="specEdit.stat" @change="update('stat')">
          <option v-for="(option, index) in options.stat" :value="option.value" :key="'stat' + index">
            {{ option.text }}
          </option>
        </select>
        <div class="form-text">Statistical transformation</div>
      </div>

      <div class="col-md-2 mt-2 aggregation-column" v-if="options.aggregation.length > 0">
        <select class="form-select form-select-sm" id="aggregation" v-model="specEdit.aggregation"
          @change="update('aggregation')">
          <option v-for="(option, index) in options.aggregation" :value="option.value" :key="'aggregation' + index">
            {{ option.text }}
          </option>
        </select>
        <div id="aggregation-label" class="form-text">Aggregation period</div>
      </div>
      <div v-for="(period, index) in datePeriods" :key="index">
        <div class="mt-2 row row-cols-2 row-cols-sm-2 row-cols-md-3">
          <div class="col-md-2 date-column">
            <VueDatePicker v-model="period[0]" @update:model-value="handleDateUpdate()" text-input :dark="darkMode"
              :enable-time-picker="false" />
            <div class="form-text">From date {{ specEdit.periods.length > 1 ? index + 1 : '' }}</div>
          </div>
          <div class="col-md-2 date-column">
            <VueDatePicker v-model="period[1]" @update:model-value="handleDateUpdate()" text-input :dark="darkMode"
              :enable-time-picker="false" />
            <div class="form-text">To date {{ specEdit.periods.length > 1 ? index + 1 : '' }}</div>
          </div>
          <div class="col-md-2" v-if="index === 0">
            <button class="btn btn-sm btn-secondary me-2 mb-2" title="Add date range" @click="addPeriod">
              <i class="bi bi-plus"></i>
            </button>
            <button v-if="datePeriods.length > 1" class="btn btn-sm btn-warning mb-2" title="Remove date range"
              @click="removePeriod()">
              <i class="bi bi-dash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>


  <div class="row row-cols-1 row-cols-sm-1 row-cols-md-12">
    <div class="col-md-2 mt-2 display-column" v-if="options.display">
      <select class="form-select form-select-sm" v-model="specEdit.display" @change="update('display')">
        <option v-for="(option, index) in options.display" :key="'display' + index" :value="option.value">
          {{ option.text }}
        </option>
      </select>
      <div class="form-text">Display mode</div>
    </div>
  </div>
  <hr />

  <div class="mb-2">
    <div class="btn-group me-2">
      <button class="btn btn-secondary btn-sm" @click="previewSpec()" :disabled="publishing">
        <div v-if="publishing" class="spinner-border spinner-border-sm animate-spin" role="status" aria-hidden="true">
        </div>
        <div v-else><i class="bi bi-play"></i> Preview {{ partitionLabel() }}</div>
      </button>

      <button class="btn btn-secondary btn-sm dropdown-toggle dropdown-toggle-split" type="button" id="previewDropdown"
        data-bs-toggle="dropdown" aria-expanded="false">
        <span class="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="previewDropdown">
        <li><button class="dropdown-item" type="button" :class="{ active: autoPreview }" @click="autoPreview = true">
            Auto
          </button></li>
        <li><button class="dropdown-item" type="button" :class="{ active: !autoPreview }" @click="autoPreview = false">
            Manual </button></li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li><button class="dropdown-item" type="button" @click="previewData = null"> Close Preview </button></li>
      </ul>
    </div>

    <div v-if="user.role === 'admin'" class="float-end form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="showDebug" >
      <label class="form-check-label" for="flexSwitchCheckDefault">Debug</label>
    </div>
  </div>

  <div class="mt-2" v-if="previewData">
    <!-- <div id="preview">{{ previewData }}</div> -->

    <div v-if="previewData.url">
      <img :src="previewData.url" />
    </div>

    <div v-else-if="previewData.html">
      <div v-html="previewData.html.replaceAll('\n', '<br>')"></div>
    </div>

    <div v-else>
      <div v-if="previewData.name === 'Error'" class="alert alert-danger visible">
        {{previewData.message}}
      </div>
      <div v-else id="preview"></div>
    </div>
    <div class="">processing time: {{ publishTime.toFixed(3) }}s</div>
  </div>

  <div v-if="user.role === 'admin' && showDebug">
    <div class="p-2 card admin-area">
      <b><i class="bi-code"></i> Mixin Code</b>
      <textarea class="form-control spec-textarea" v-model="specPreview" placeholder="Mixin specification"
        id="mixinTextarea" @input="validateSpecPreview()"></textarea>
      <div class="mt-2 " v-if="specPreviewError">
        <div class="alert alert-danger" role="alert">
          {{ specPreviewError }}
        </div>
      </div>

      <div v-if="previewData">
        <b class="mt-2"><i class="bi bi-funnel"></i> Processing Info</b>

        <code v-if="previewData.mixin" class="trace">{{ previewData }}</code>
        <code v-else class="trace">{{ JSON.stringify(previewData, null, 2).replace(/\\n/g, '\n') }}</code>
      </div>
    </div>
  </div>

  <div class="accordion mt-2 accordion-sm" id="mixinAccordion">

    <div class="accordion-item">
      <h2 class="accordion-header" id="mixinDataHeader">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapse-mixin-data" aria-expanded="false" aria-controls="collapse-mixin-data">
          <i class="bi bi-table me-2"></i> Data
        </button>
      </h2>
      <div id="collapse-mixin-data" class="accordion-collapse collapse" aria-labelledby="mixinDataHeader"
        data-bs-parent="#mixinAccordion">
        <div v-if="previewData" class="accordion-body">

          <div v-if="previewData?.mixin?.data" class="my-2">
            <div>name,x,y</div>
            <div v-for="(d, index) in previewData.mixin.data" :key="index">
              {{ d.name }},{{ d.x }}{{ d.y ? ',' + d.y : '' }}
            </div>
          </div>

          <div><b>Metabase:</b> <a target="_blank" :href="previewData?.mixin?.data_args?.metabase_url">{{ previewData?.mixin?.spec?.dataset }} dashboard</a></div>
          <div><b>Display:</b> {{ previewData?.mixin?.spec?.display }}</div>
          <div><b>Dataset:</b> {{ previewData?.mixin?.spec?.dataset }}</div>
          <div><b>Category:</b> {{ previewData?.mixin?.spec?.description }}</div>
          <div><b>Tables queried:</b>        
            <div class="ms-3 mb-1" v-for="(d, index) in previewData?.mixin?.data_args?.tables" :key="index">
              <div>{{ d.name }}</div>
              <div v-if="d?.filter?.date_column_name">{{ d?.filter?.date_column_name }} as date</div>
              <div v-for="(c, cindex) in d?.filter?.columns" :key="cindex">
                <div>{{ c.name }} IN {{ c.values.map(x => `"${x}"`).join(', ') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!publishing" class="accordion-body">
          <div class="alert alert-danger visible">
            Nothing to display
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="mixinSettingsHeader">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target="#mixinCollapseSettings" aria-expanded="false" aria-controls="mixinCollapseSettings">
          <i class="bi bi-gear me-2"></i> Preview Settings
        </button>
      </h2>
      <div id="mixinCollapseSettings" class="accordion-collapse collapse" aria-labelledby="mixinSettingsHeader"
        data-bs-parent="#mixinAccordion">
        <div class="accordion-body">
          <h5>Location</h5>

          <div class="row row-cols-1 row-cols-md-12">

            <div v-if="options.publishCity" class="col-md-2 mb-2 settings-city-column">
              {{ options.publishCity.name }}
              <div class="form-text">City</div>
            </div>

            <div class="col-md-3 mb-2 settings-partition-column">
              <select class="form-select form-select-sm" v-model="print_settings.partition">
                <option v-for="(option, index) in options.publishPartitions" :value="option.value"
                  :key="'partition' + index">
                  {{ option.text }}
                </option>
              </select>
              <div class="form-text">Partition</div>
            </div>
          </div>

          <h5>Image</h5>

          <div class="row row-cols-1 row-cols-md-12">

            <div class="col-md-2 mb-2 settings-image-column">
              <select class="form-select form-select-sm" v-model="print_settings.imageFormat">
                <option v-for="(option, index) in imageFormatOptions" :value="option.value"
                  :key="'imageFormat' + index">
                  {{ option.text }}
                </option>
              </select>
              <div class="form-text">Format</div>
            </div>
            <div class="col-md-1 mb-2 settings-size-column">
              <input class="form-control form-select-sm" type="number" v-model="print_settings.imageWidth">
              <div class="form-text">Width</div>
            </div>
            <div class="col-md-1 mb-2 settings-size-column">
              <input class="form-control form-select-sm" type="number" v-model="print_settings.imageHeight">
              <div class="form-text">Height</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="mixinInfoHeader">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapse-mixin-info" aria-expanded="false" aria-controls="collapse-mixin-info">
          <i class="bi bi-palette-fill me-2"></i> Theme
        </button>
      </h2>
      <div id="collapse-mixin-info" class="accordion-collapse collapse" aria-labelledby="mixinInfoHeader"
        data-bs-parent="#mixinAccordion">
        <div class="accordion-body">
          <h2>{{ props.account_id }} theme</h2>
          <ThemeViewer :account_id="props.account_id" />
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
import { api, authHeaders } from '../api';
import embed from 'vega-embed';

import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import ThemeViewer from '../components/ThemeViewer.vue';

import dayjs from 'dayjs';

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

const props = defineProps({
  account_id: {
    type: String,
    required: true,
  }
});

const showDebug = ref(false);
const publishTime = ref(0);
const multiSelectTheme = ref('dark-mode');
const darkMode = ref(true);
const clipboardText = ref('');
const autoPreview = ref(true);
const options = reactive({
  display: [],
  customDisplay: [],
  data: [],
  dataset: [],
  category: [],
  stat: [],
  aggregation: [],
  partitions: [],
  publishPartitions: []
});

const options_ = {
  display: [],
  customDisplay: [],
  data: [],
  dataset: [],
  category: [],
  stat: [],
  aggregation: [],
  partitions: [],
};

const toDatePeriods = (periods) => (
  periods.map((d) => ([
    dayjs(d[0], import.meta.env.VITE_DATE_FORMAT).toDate(),
    dayjs(d[1], import.meta.env.VITE_DATE_FORMAT).toDate()
  ]))
);

const toStringDatePeriods = (datePeriods) => (
  datePeriods.map(d => ([
    dayjs(d[0]).format(import.meta.env.VITE_DATE_FORMAT),
    dayjs(d[1]).format(import.meta.env.VITE_DATE_FORMAT)
  ]))
);

const imageFormatOptions = ref([]);

const print_settings = reactive({});

const datePeriods = ref([]);

const specDefault = reactive({
  description: 'default',
  display: 'text',
  stat: 'identity',
  dataset: '',
  category: '',
  partitions: [], //use print default
  aggregation: 'period',
  periods: [[
    dayjs(dayjs().startOf('month').subtract(1, 'month').subtract(1, 'year'))
      .format(import.meta.env.VITE_DATE_FORMAT),
    dayjs(dayjs().startOf('month').subtract(1, 'month'))
      .format(import.meta.env.VITE_DATE_FORMAT)
  ]],
  min_periods: 1,
});

const specPreview = ref('');
const specPreviewError = ref('');

const specEdit = reactive({
  data: '',
  partitions: [],
  display: '',
  aggregation: '',
  periods: [],
  description: '',
  stat: ''
});

const previewData = ref();
const publishing = ref(false);

function getSpec(spec) {
  spec = JSON.parse(JSON.stringify(spec));

  if (!spec.stat) delete spec.stat;
  if (!spec.aggregation) delete spec.aggregation;
  if (!spec.periods.length) delete spec.periods;

  if (!spec.partitions || spec.partitions.length === 0) {
    delete spec.partitions;
  } else {
    let partitions = [];
    spec.partitions.forEach((d) => {
      if (Array.isArray(d)) { //all partitions
        partitions = partitions.concat(d);
      } else {
        partitions.push(d);
      }
    });

    partitions = partitions.length === 0 ? undefined : partitions;
    // : partitions.length === 1
    //   ? partitions[0]

    spec.partitions = partitions;
  }

  return spec;
}

const partitionLabel = () => {
  const partitions = specEdit.partitions;
  if (partitions.length == 0) {
    const partition = options.publishPartitions.find((d) => d.id == print_settings.partition);
    return partition ? partition.text : '';
  } else if (partitions.length == 1) {
    const partition = options.publishPartitions.find((d) => d.id == partitions[0]);
    return partition ? partition.text : '';
  } else {
    return 'multiple partitions';
  }
};

const copyToClipboard = () => {
  const textToCopy = '`' + JSON.stringify(getSpec(specEdit)) + '`';

  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      clipboardText.value = textToCopy;
    })
    .catch((error) => {
      console.error('Failed to copy text to clipboard:', error);
    });
};

const addPeriod = () => {
  const last = specEdit.periods[specEdit.periods.length - 1];
  const num = dayjs(last[1]).diff(last[0], 'day');
  specEdit.periods.push([
    dayjs(last[0]).subtract(num, 'day').format(import.meta.env.VITE_DATE_FORMAT),
    dayjs(last[1]).subtract(num, 'day').format(import.meta.env.VITE_DATE_FORMAT)
  ]);
  datePeriods.value = toDatePeriods(specEdit.periods);
  update('period');
};

function removePeriod() {
  specEdit.periods.pop();
  datePeriods.value = toDatePeriods(specEdit.periods);
  update('period');
};

function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : 'Oops looks like we got an empty string to capitalize!';
};

const previewSpec = async () => {
  if (publishing.value) return;

  try {
    publishing.value = true;
    previewData.value = null;
    const startTime = performance.now(); // Start measuring processing time
    const result = await api.post('mixins/print',
      { print_settings: print_settings, spec: JSON.parse(specPreview.value) },
      authHeaders(),
      { timeout: 40000 }, //XXX: wait for 40 seconds}
    );
    const endTime = performance.now(); // Stop measuring processing time
    publishTime.value = (endTime - startTime) / 1000.0; // Calculate processing time in sec
    publishing.value = false;
    previewData.value = result.data;
    if (previewData.value.vlSpec) embed('#preview', previewData.value.vlSpec);
  } catch (error) {
    publishing.value = false;
  }
};

function handleDateUpdate() {
  if (datePeriods.value.every(d => d[0] instanceof Date && d[1] instanceof Date)) {
    update('period');
  }
}

function update(prop) {
  clipboardText.value = '';

  if (prop == 'display') {
    const def = options.display.find((d) => specEdit.display === d.value);
    for (const [key, value] of Object.entries(def?.init)) {
      specEdit[key] = value;
    }
  } else if (prop == 'dataset') {
    options.category = options.datasets_categories[specEdit.dataset];
    specEdit.category = options.category[0].value;

    const def = options.data.find(d => d.key === specEdit.dataset + '-' + specEdit.category);

    if (def?.options) {
      options.display = def.options.display ? def.options.display.map(d => options_.display.find(e => e.value === d)) : JSON.parse(JSON.stringify(options_.display));
      specEdit.display = options.display.find(d => d.value == specEdit.display) ? specEdit.display : options.display[0].value;

      options.stat = def.options.stat ? def.options.stat.map(d => options_.stat.find(e => e.value === d)) : JSON.parse(JSON.stringify(options_.stat));
      specEdit.stat = options.stat.find(d => d.value == specEdit.stat) ? specEdit.stat : options.stat[0]?.value;

      options.aggregation = def.options.aggregation ? def.options.aggregation.map(d => options_.aggregation.find(e => e.key === d)) : JSON.parse(JSON.stringify(options_.aggregation));
      specEdit.aggregation = options.aggregation.find(d => d.value == specEdit.aggregation) ? specEdit.aggregation : options.aggregation[0]?.value;

      specEdit.periods = def.options.periods ? def.options.periods : JSON.parse(JSON.stringify(specDefault.periods));
    } else {
      options.stat = JSON.parse(JSON.stringify(options_.stat));
      specEdit.stat = specEdit.stat ? specEdit.stat : options.stat[0].value;

      options.aggregation = JSON.parse(JSON.stringify(options_.aggregation));
      specEdit.aggregation = specEdit.aggregation ? specEdit.aggregation : options.aggregation[0].value;

      options.display = JSON.parse(JSON.stringify(options_.display));
      specEdit.display = specEdit.display ? specEdit.display : options.display[0].value;

      specEdit.periods = JSON.parse(JSON.stringify(specDefault.periods));
    }

    if (def?.init) {
      for (const [key, value] of Object.entries(def.init)) {
        specEdit[key] = value;
      }
    }

    initSpecEdit();
  }

  specEdit.periods = toStringDatePeriods(datePeriods.value);

  specEdit.data = `${specEdit.dataset}-${specEdit.category}`;

  const displayDesc = options.display.find((d) => d.value === specEdit.display)?.description;
  const dataDesc = options.data.find((d) => d.key === specEdit.data)?.description;
  let statDesc = options.stat.find((d) => d.value === specEdit.stat)?.description;
  statDesc = statDesc ? statDesc === 'Identity' ? '' : ' ' + statDesc.toLowerCase() : '';
  specEdit.description = capitalize(`${dataDesc}${statDesc} as ${displayDesc}`);

  specPreview.value = JSON.stringify(getSpec(specEdit), null, 2);

  if (autoPreview.value) previewSpec();
};

function initSpecEdit() {
  let def = options.aggregation.find((d) => d.value === specEdit.aggregation);
  if (def?.init) {
    for (const [key, value] of Object.entries(def.init)) {
      specEdit[key] = specEdit[key] ? value : specEdit[key];
    }
  }

  def = options.stat.find((d) => d.value === specEdit.stat);
  if (def?.init) {
    for (const [key, value] of Object.entries(def.init)) {
      specEdit[key] = specEdit[key] ? value : specEdit[key];
    }
  }

  def = options.dataset.find((d) => d.value === specEdit.dataset);
  if (def?.init) {
    for (const [key, value] of Object.entries(def.init)) {
      specEdit[key] = specEdit[key] ? value : specEdit[key];
    }
  }
  options.category = options.datasets_categories[specEdit.dataset];
  specEdit.category = options.category[0].value;

  def = options.category.find((d) => d.value === specEdit.category);
  if (def?.init) {
    for (const [key, value] of Object.entries(def.init)) {
      specEdit[key] = specEdit[key] ? value : specEdit[key];
    }
  }

  def = options.display.find((d) => d.value === specEdit.display);
  if (def?.init) {
    for (const [key, value] of Object.entries(def.init)) {
      specEdit[key] = specEdit[key] ? value : specEdit[key];
    }
  }
};

function reset() {
  clipboardText.value = '';
  previewData.value = undefined;
  specEdit.display = specDefault.display;
  specEdit.dataset = specDefault.dataset;
  specEdit.category = specDefault.category;
  specEdit.aggregation = specDefault.aggregation;
  specEdit.stat = specDefault.stat;
  specEdit.partitions = specDefault.partitions;
  specEdit.periods = JSON.parse(JSON.stringify(specDefault.periods));
  datePeriods.value = toDatePeriods(specEdit.periods);

  options.display = JSON.parse(JSON.stringify(options_.display));
  options.stat = JSON.parse(JSON.stringify(options_.stat));
  options.aggregation = JSON.parse(JSON.stringify(options_.aggregation));

  initSpecEdit();

  specEdit.data = `${specEdit.dataset}-${specEdit.category}`;

  //set spec description
  const display = options.display.find((d) => specEdit.display === d.value);
  const data = options.data.find((d) => d.key === specEdit.data);
  const stat = options.stat.find((d) => d.key === specEdit.stat);
  specEdit.description = capitalize(`${data.description}${stat.description === 'Identity' ? '' : ' ' + stat.description.toLowerCase()} as ${display.description}`);

  specPreview.value = JSON.stringify(getSpec(specEdit), null, 2);
};

const init = async () => {
  const results = (await api.get(`mixins/mixin_defs/${props.account_id}`, authHeaders())).data;
  Object.assign(print_settings, {
    city: results.print_defaults.city,
    account_id: results.print_defaults.account_id,
    partition: results.print_defaults.partition, //results.partition_options[1].id,
    imageFormat: results.print_defaults.image.format,
    imageWidth: results.print_defaults.image.width,
    imageHeight: results.print_defaults.image.height
  });

  options.display = [];
  options.customDisplay = [];

  results.display_options.forEach((d) => {
    if (d.renderer.includes('custom')) {
      options.customDisplay.push({
        text: capitalize(d.description),
        value: d.key,
        ...d,
      });
    } else {
      options.display.push({
        text: capitalize(d.description),
        value: d.key,
        ...d,
      });
    }
  });

  options_.display = JSON.parse(JSON.stringify(options.display));

  options.stat = results.stat_options.map((d) => {
    return {
      text: capitalize(d.description),
      value: d.key,
      ...d,
    };
  });
  options_.stat = JSON.parse(JSON.stringify(options.stat));

  options.publishPartitions = results.partition_options.map((d) => {
    return { text: d.name, value: d.key, id: d.id };
  });

  options.partitions = [
    { text: 'All partitions', value: 'all', id: 'all' },
    //{ text: 'Current partition', value: 'context', id: 'context' },
    ...options.publishPartitions
  ];

  options.publishCity = results.city_options.find(d => d.id === user.city_id);
  options.city = user.city_id;

  options.data = results.data_options;

  options.dataset = results.datasets.map(d => {
    return {
      text: capitalize(d),
      value: d
    };
  });

  options.datasets_categories = results.datasets_categories;
  results.datasets.forEach(d => {
    options.datasets_categories[d] = options.datasets_categories[d].map(c => {
      return {
        text: capitalize(c.description),
        value: c.category
      };
    });
  });

  options.category = options.datasets_categories[options.dataset[0].value];

  options.aggregation = results.aggregation_options.map((d) => {
    return {
      text: capitalize(d.description),
      value: d.key,
      ...d,
    };
  });

  options_.aggregation = JSON.parse(JSON.stringify(options.aggregation));

  imageFormatOptions.value = results.image_format_options.map((d) => {
    return {
      text: d.description,
      value: d.key,
      ...d,
    };
  });

  specDefault.dataset = options.dataset[0].value;
  specDefault.category = options.category[0].value;

  reset();
};

function validateSpecPreview() {
  specPreviewError.value = '';

  try {
    JSON.parse(specPreview.value, null, 2);
  } catch (e) {
    specPreviewError.value = e.message;
  }
}

onMounted(async () => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);
  init();
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  multiSelectTheme.value = prefersDarkTheme.matches ? 'dark-mode' : '';
  darkMode.value = prefersDarkTheme.matches;
}
</script>

<style scoped>
.trace {
  display: block;
  white-space: pre;
  overflow-x: auto;
  margin: 0 0 0 0px;
  padding: 3px;
  border-radius: 3px;
  word-break: break-all;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.72em;
  background-color: #222529;
  color: #f8f9fa;
}

.spec-textarea {
  height: auto;
  min-height: 280px;
  resize: vertical;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.72em;
  color: #f8f9fa;
}

.dark-mode :deep(.multiselect-wrapper) {
  background-color: #222529;
  color: white !important;
}

.dark-mode :deep(.multiselect-tags) {
  background-color: #222529;
  color: white !important;
}

.dark-mode :deep(.multiselect-options) {
  background-color: #222529;
  color: white !important;
}

.accordion-sm .accordion-button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.accordion-sm .accordion-collapse {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.dataset-column {
  min-width: 180px;
}

.category-column {
  min-width: 180px;
}

.stat-column {
  min-width: 180px;
}

.aggregation-column {
  min-width: 180px;
}

.date-column {
  min-width: 180px;
}

.display-column {
  min-width: 180px;
}

.settings-city-column {
  min-width: 180px;
}

.settings-partition-column {
  min-width: 280px;
}

.settings-image-column {
  min-width: 200px;
}

.settings-size-column {
  min-width: 100px;
}

.admin-area {
  background-color: rgb(255, 162, 56) !important;
  color: #000000a6 !important;
}
</style>