<template>
  <main class="home" v-if="user">
    <h1>Crosstown Foundry</h1>

    <p>Crosstown Foundry lets you create newsletters with partition level data for an entire city. The newsletters or <b>News</b> use <b>Mixins</b> to specify data-driven text or graphics. Published newsletters or <b>Prints</b> are generated for each partition as HTML pages.</p>

    <div class="row mb-2">
      <div :class="{ 'col-sm-4': user.role === 'admin', 'col-sm-6': user.role !== 'admin' }">
        <div class="card h-100 mb-2">
          <div class="card-body">
            <h5 class="card-title"><i class="bi bi-newspaper bi-primary"></i> News</h5>
            <p class="card-text"><span class="fw-bold">News</span> consist of an HTML template and a markdown content in which you can define text and graphics from partition level data. <a target="_blank" href="https://www.notion.so/8f6313ee3b714f329b214e27a0b40a9a?v=9eaed5aeb0e348f4b77ab01c1ae0b4cb&pvs=4">Learn more.</a></p>
            <button type="button" class="btn btn-secondary" @click="$router.push({ name: 'news' })">Go to News</button>
          </div>
        </div>
      </div>
      <div :class="{ 'col-sm-4': user.role === 'admin', 'col-sm-6': user.role !== 'admin' }">
        <div class="card h-100 mb-2">
          <div class="card-body">
            <h5 class="card-title"><i class="bi bi-printer"></i> Prints</h5>
            <p class="card-text"><span class="fw-bold">Prints</span> consist of published <span
                class="fw-bold">News</span> that are generated for a set of specific partitions in a city. <a target="_blank" href="https://www.notion.so/8f6313ee3b714f329b214e27a0b40a9a?v=9eaed5aeb0e348f4b77ab01c1ae0b4cb&pvs=4">Learn more.</a></p>
            <button type="button" class="btn btn-secondary" @click="$router.push({ name: 'prints' })">Go to
              Prints</button>
          </div>
        </div>
      </div>
      <div class='col-sm-4' v-if="user.role === 'admin'">
        <div class="card h-100 mb-2">
          <div class="card-body">
            <h5 class="card-title"><i class="bi bi-puzzle"></i> Mixins</h5>
            <p class="card-text"><span class="fw-bold">Mixins</span> are data-driven components used in <span
                class="fw-bold">News</span></p>
            <button type="button" :class="['btn', adminColorClass]" @click="$router.push({ name: 'mixins'})">Go to
              Mixins</button>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="ms-2">
        <div v-for="(access, index) in user.access" v-bind:key="index">You can create newsletters for {{ access.name }}{{ access.send ? ` and can send published newsletters to subscribers.` : '.' }}</div>
      </div>
    </div>

    <div>{{ version }}</div>
  </main>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { api } from '../api';

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

const version = ref('');
const adminColorClass = ref('crosstown-admin-dark-btn');

onMounted(async () => {
  version.value = `${import.meta.env.VITE_RELEASE_NAME} ${import.meta.env.VITE_VERSION} ${import.meta.env.VITE_GIT_HASH}`;
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  adminColorClass.value = prefersDarkTheme.matches ? 'crosstown-admin-btn' : 'crosstown-admin-dark-btn';
}
</script>

<style scoped>
.crosstown-admin-dark-btn {
  background-color: rgb(197, 105, 0) !important;
  color: #ffffff !important;
}
.crosstown-admin-btn {
  background-color: rgb(255, 162, 56) !important;
  color: #000000a6 !important;
}
</style>
