<template>
  <div v-if="print && print.publications && print.publications.partitions && partitionMap">
    <div v-if="print.publications.partitions.length > 0">
      <div>
        <div class="row mt-2">
          <div class="col-md-4">
            <Multiselect v-model="selfSendPartitions" :options="optionsPartitions" track-by="value" label="text"
              mode="tags" :close-on-select="false" :class="darkMode ? 'dark-mode' : ''">
            </Multiselect>
            <div class="form-text">Newsletters</div>
          </div>
          <div class="col-md-4 align-self-end">
            <button v-if="!sendingToSelf" :disabled="!selfSendPartitions.length" class="btn btn-secondary"
              @click="sendToSelf">
              <i class="bi bi-send"></i> Email {{ user.email }}
            </button>
            <button v-else class="btn btn-secondary">
              <span v-if="sendingToSelf" class="spinner-border spinner-border-sm"></span> Email {{ user.email }}
            </button>
            <div class="form-text">&nbsp;</div>
          </div>
        </div>
      </div>

      <div v-if="sendToSelfResult.status === 'success'"
        class="alert alert-info alert-dismissible fade show mt-2" role="alert">
        {{ sendToSelfResult.message }}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"  @click="dismissAlert"></button>
      </div>
      <div v-else-if="sendToSelfResult.status" class="alert alert-danger mt-2" role="alert">
        <div>{{ sendToSelfResult.message }}</div>
        <div>command: {{ sendToSelfResult.command }}</div>
        <div>stdout: {{ sendToSelfResult.stdout }}</div>
        <div>stderr: {{ sendToSelfResult.stderr }}</div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"  @click="dismissAlert"></button>
      </div>

      <div v-if="print.publications.partitions.every(d => d.index !== '')">
        <div class="mt-3 card border-danger p-3" :class="print.send_info ? 'border-info' : 'border-danger'">
          <div v-if="sendSubscribersOutput">
            <code class="text-muted">{{ sendSubscribersOutput }}</code>
          </div>
          <div v-if="print.send_info">
            <div v-if="print.send_info.end_timestamp && print.send_info.count === print.send_info.total">
              Sent to {{ print.send_info.count }} subscribers on {{ formatDate(print.send_info.end_timestamp) }}
            </div>
            <div v-else>
              Sending to subscribers {{ print.send_info.count }} of {{ print.send_info.total }}...
              <div class="progress ml-2 w-25">
                <div class="progress-bar" role="progressbar"
                  :style="{ width: (print.send_info.total && print.send_info.count) ? ((print.send_info.count / print.send_info.total) * 100).toFixed(1) + '%' : 0 }"
                  :aria-valuenow="(print.send_info.total && print.send_info.count) ? ((print.send_info.count / print.send_info.total) * 100).toFixed(1) : 0"
                  :aria-valuemin="0" :aria-valuemax="100">
                  {{ (print.send_info.total && print.send_info.count) ? ((print.send_info.count / print.send_info.total)
                    * 100).toFixed(1) : 0 }}%
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h4 class="text-danger">Email  Newsletters to Subscribers</h4>
            <div class="my-2">
              Enter '{{ print.id }}' to email newsletters to subscribers
            </div>
            <form class="form-inline">
              <input class="form-control mb-2" type="text" v-model="sendSubscribersVerificationCode"
                placeholder="Enter print id" name="subscribers-verification-code">
            </form>
            <button class="btn btn-danger mt-2" :disabled="sendSubscribersVerificationCode !== print.id"
              @click="onSendToSubscribers()">Send</button>
          </div>
        </div>

      </div>
      <div class="mt-2" v-else>
        All {{ partitionMap.size - 1 }} partitions publications are required to send to subscribers
      </div>
    </div>
  </div>
  <div v-else>There are no publications to send</div>
</template>

<script setup>
import { api, authHeaders } from '../api';
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

const REFRESH_SEND_INFO_INTERVAL = 20000;

const props = defineProps({
  modelValue: Object,
});

const print = ref(undefined);
const sendToSelfResult = ref({ status: '', message: '' });
const sendingToSelf = ref(false);
const sendSubscribersVerificationCode = ref('');
const sendSubscribersOutput = ref('');
const partitionMap = ref(undefined);
const timer = ref(undefined);
const darkMode = ref(true);
const multiSelectTheme = ref('dark-mode');
const selfSendPartitions = ref([]);
const optionsPartitions = ref([]);

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

function formatDate(date) {
  return date ? dayjs(date).format(import.meta.env.VITE_DATETIME_FORMAT) : 'NA';
}

function dismissAlert() {
  sendToSelfResult.value = {}; // Reset the result state
}

async function sendToSelf() {
  sendToSelfResult.value = { status: '', message: '' };
  sendingToSelf.value = true;

  const result = await api.post(
    'mailer/send',
    {
      print_id: print.value.id,
      emails: [user.email],
      partitions: selfSendPartitions.value
    },
    authHeaders()
  );

  sendingToSelf.value = false;
  sendToSelfResult.value = result.data;
};

async function onSendToSubscribers() {
  print.value.send_info = {};
  sendSubscribersVerificationCode.value = '';
  const result = (await api.post('mailer/send-subscribers', { print_id: print.value.id }, authHeaders())).data;
  sendSubscribersOutput.value = `${result.message} ${result.error ? '' + result.error.message : ''}`;
  timer.value = setInterval(refreshSendInfo, REFRESH_SEND_INFO_INTERVAL);
}

async function refreshSendInfo() {
  const result = (await api.get('prints/' + print.value.id, authHeaders())).data;
  print.value = result;
  if (print.value?.send_info?.end_timestamp) {
    clearInterval(this.timer);
  }
  // this.$emit('change', this.print);
}

watch(() => props.modelValue, (newValue) => {
  print.value = newValue;

  if (print.value.publications?.partitions?.length > 0) {
    //set options for multiselect according to the partitions in print
    optionsPartitions.value = print.value.publications.partitions.map(d => {
      const partition = partitionMap.value.get(d.id);
      return { value: partition.id, text: partition.name };
    });

    //set selected partition for self send to the first partition in the available partitions options
    selfSendPartitions.value = [optionsPartitions.value[0].value];
  } else {
    selfSendPartitions.value = [];
    optionsPartitions.value = [];
  }
});

onMounted(async () => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);

  print.value = JSON.parse(JSON.stringify(props.modelValue));

  if (print.value.send_info && !print.value.send_info.end_timestamp) {
    refreshSendInfo();
    timer.value = setInterval(refreshSendInfo, REFRESH_SEND_INFO_INTERVAL);
  }

  partitionMap.value = new Map();
  if (print.value.city) {
    const partitions = (await api.get(`prints/partitions/${print.value.city}`, authHeaders())).data;
    partitions.forEach(d => partitionMap.value.set(d.id, { id: d.id, name: d.name }));

    if (print.value.publications?.partitions?.length > 0) {
      //set options for multiselect according to the partitions in print
      optionsPartitions.value = print.value.publications.partitions.map(d => {
        const partition = partitionMap.value.get(d.id);
        return { value: partition.id, text: partition.name };
      });

      //set selected partition for self send to the first partition in the available partitions options
      selfSendPartitions.value = [optionsPartitions.value[0].value];
    }
  }
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  multiSelectTheme.value = prefersDarkTheme.matches ? 'dark-mode' : '';
  darkMode.value = prefersDarkTheme.matches;
}
</script>

<style scoped>
code.trace {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: normal;
  /* min-height: 100px; */
  margin: 0 0 0 0px;
  padding: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  font-size: 0.72em;
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
}

.dark-mode :deep(.multiselect-wrapper) {
  background-color: #222529;
  color: white !important;
}

.dark-mode :deep(.multiselect-tags) {
  background-color: #222529;
  color: white !important;
}

.dark-mode :deep(.multiselect-options) {
  background-color: #222529;
  color: white !important;
}
</style>