<template>
  <main role="main">
    <div v-if="rows" class="container-fluid">
      <div class="row">
        <div class="col d-flex align-items-end">
          <div class="small mb-0 text-muted">
            count {{ rows.length }}
          </div>
        </div>
        <div class="col-auto">
          <button id="news-add-button" class="btn btn-sm btn-secondary my-2" @click="router.push({ name: 'newsAdd' })">
            <i class="bi bi-plus"></i> Add
          </button>
        </div>
      </div>

      <div class="d-none d-lg-block">
        <VueGoodTable :columns="columns" :rows="rows" :search-options="{ enabled: true }" :paginate="true" compactMode
          :group-options="{ enabled: false }" :theme="tableTheme" :sort-options="{
            enabled: true,
            initialSortBy: { field: 'last_modified', type: 'desc' }
          }">
          <template #table-row="props">
            <span v-if="props.column.field == 'edit'">
              <button class="btn btn-secondary btn-sm" @click="onEditButtonClick(props.row)">Edit</button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </VueGoodTable>
      </div>

      <div class="d-lg-none">
        <VueGoodTable :columns="columnsSmall" :rows="rows" :search-options="{ enabled: true }" :paginate="true"
          compactMode :group-options="{ enabled: false }" :theme="tableTheme" :sort-options="{
            enabled: true,
          }">
          <template #table-row="props">
            <span v-if="props.column.field == 'edit'">
              <button class="btn btn-secondary btn-sm" @click="onEditButtonClick(props.row)">Edit</button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </VueGoodTable>
      </div>

    </div>

    <div v-else>
      <p class="text-center">Loading...</p>
      <div class="overlay d-flex align-items-center justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

  </main>
</template>

<script setup>
import { api, authHeaders } from '../api';
import dayjs from 'dayjs';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';

import { ref, onMounted, onBeforeUnmount } from 'vue';

const columns = [
  { label: 'Name', field: 'name' },
  { label: 'Subject / Title', field: 'subject' },
  { label: 'Is Printed', field: 'has_print' },
  { label: 'Last Modified', field: 'last_modified', type: 'date', dateInputFormat: 'yyyy-MM-dd', dateOutputFormat: 'yyyy-MM-dd' },
  { label: '', field: 'edit', sortable: false }
];

const columnsSmall = [
  { label: 'Name', field: 'name' },
  { label: '', field: 'edit', sortable: false }
];

const rows = ref([]);
const tableTheme = ref('nocturnal bg-dark');

import { useRouter } from 'vue-router';
const router = useRouter();

const onEditButtonClick = (row) => {
  router.push({ name: 'newsEdit', params: { id: row.id } });
};

onMounted(async () => {
  document.title = 'Foundry News';

  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);

  const news = (await api.get('news', authHeaders())).data;
  const prints = (await api.get('prints', authHeaders())).data;
  const cities = await api.get('datasets/cities', authHeaders());

  rows.value = news.map(d => {
    return {
      id: d.id,
      name: d.name,
      last_modified: dayjs(d.last_modified.timestamp).format('YYYY-MM-DD'),
      has_print: prints.find(p => p.news_id === d.id) ? 'Yes' : 'No',
      subject: d.subject,
      template: d.template,
      city: cities.data.find(c => c.id === d.city).name,
    }
  });
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  tableTheme.value = prefersDarkTheme.matches ? 'nocturnal bg-dark' : '';
}
</script>

<style scoped>
:deep(.vgt-table.nocturnal) {
  background-color: #343a40 !important;
}
</style>